// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

const linkResolver = (doc) => {
  if (!doc) return "/"

  switch (doc.type) {
    case "edit":
      return `/edits/${doc.uid}`
    case "standard_content_page":
      return "/" + doc.uid
    case "category":
      return `/category/${doc.uid}`
    case "latest_favs_listing":
      return "/" + doc.uid
    case "latest_edits_listing":
      return "/" + doc.uid
    case "shop_my_instagram":
      return "/" + doc.uid
    case "link":
      return doc.url
    default:
      return "/"
  }

  // Homepage route fallback
}

module.exports = linkResolver
